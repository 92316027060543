// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konsultacja-dermatologiczna-online-js": () => import("./../../../src/pages/konsultacja-dermatologiczna-online.js" /* webpackChunkName: "component---src-pages-konsultacja-dermatologiczna-online-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polecamy-js": () => import("./../../../src/pages/polecamy.js" /* webpackChunkName: "component---src-pages-polecamy-js" */),
  "component---src-pages-promocje-js": () => import("./../../../src/pages/promocje.js" /* webpackChunkName: "component---src-pages-promocje-js" */),
  "component---src-pages-sukces-js": () => import("./../../../src/pages/sukces.js" /* webpackChunkName: "component---src-pages-sukces-js" */),
  "component---src-templates-strona-kategoria-glowna-js": () => import("./../../../src/templates/stronaKategoriaGlowna.js" /* webpackChunkName: "component---src-templates-strona-kategoria-glowna-js" */),
  "component---src-templates-strona-podkategoria-js": () => import("./../../../src/templates/stronaPodkategoria.js" /* webpackChunkName: "component---src-templates-strona-podkategoria-js" */),
  "component---src-templates-strona-pracownika-js": () => import("./../../../src/templates/stronaPracownika.js" /* webpackChunkName: "component---src-templates-strona-pracownika-js" */),
  "component---src-templates-urzadzenie-js": () => import("./../../../src/templates/urzadzenie.js" /* webpackChunkName: "component---src-templates-urzadzenie-js" */)
}

